.bp-header {
  display: -webkit-flex;
  display: flex;
}

.logo {
  height: 60%;
  display: flex;
  align-self: center;
}

.left {
  float: right;
}

.heading {
  font-size: 0.75em;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.header {
  align-items: center;
  display: flex;
  position: fixed;
  height: 66px;
  width: 100%;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 50;
  justify-content: space-between;
  box-shadow:
    0 1px 3px 0 rgba(33, 33, 33, 0.2),
    0 1px 1px 0 rgba(33, 33, 33, 0.14),
 0 2px 1px -1px rgba(33, 33, 33, 0.12);
}

.header h1 {
  float: left;
  margin: 0;
  padding: 0 16px;
  font-size: 18px;
  line-height: 56px;
  font-weight: 400;
  color: #000;
}

.header nav {
  flex: 1;
  align-self: flex-end;
  font-size: 100%;
}

.header nav a {
  display: inline-block;
  height: 66px;
  line-height: 66px;
  padding: 0 16px;
  text-align: center;
  min-width: 50px;
  background: hsla(0, 0%, 100%, 0);
  will-change: background-color;
  text-decoration: none;
  color: #000;
}

.header nav a:hover {
  color: #faa722;
}
