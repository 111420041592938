.poll-slider-form {
  width: 100%;
  margin: 0 auto;
}

.poll-slider-icon {
  margin-top: 115px;
}

.poll-sections {
  display: flex;
  justify-content: space-between;
}

.slider-config {
  display: flex;
  background: #3b3c47;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  padding: 10px 20px;
  color: white;
  min-height: 120px;
}

.slider-config label {
  margin: 10px 0;
  text-align: left;
  font-size: 12px;
  color: #fff;
  letter-spacing: 1.25px;
  line-height: 16px;
  text-transform: uppercase;
}
