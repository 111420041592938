.sidebar {
  background: #212229;
  width: 88px;
  height: calc(100vh - 66px);
  display: flex;
}

.sidebar ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.sidebar-item {
  background: #212229;
  height: 88px;
  width: 88px;
}

.sidebar-item a {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid #212229;
  line-height: 1.5;
  cursor: pointer;
}

.sidebar-item a:hover,
.sidebar-item a:active,
.sidebar-item a:focus {
  border-left: 3px solid #3abc98;
}

#outlined-bare {
  font-family: Lato-Bold;
  letter-spacing: 0;
  text-align: center;
  border-radius: 0;
}
