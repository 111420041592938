@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif;
  overflow: hidden;
  margin: 0;
  color: #191c1f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: #fff;
  outline: none;
}

a:hover {
  color: #ddd;
}

li {
  text-decoration: none;
}

/* Helper classes */

.cf::before,
.cf::after {
  content: ' ';
  display: table;
}

.cf::after {
  clear: both;
}

.container {
  overflow-y: auto;
  height: 100vh;
}

.content {
  position: relative;
  min-height: 300px;
}

.info {
  font-size: 1.1em;
  font-weight: bold;
  padding: 20vh 1em 0;
  text-align: center;
  color: #47484c;
}

.main-content {
  margin: 0;
  padding: 2em;
  text-align: center;
}

.product {
  display: inline-block;
  width: 200px;
  height: 200px;
  margin: 10px;
  border-radius: 5px;
  background: #1c1d22;
}

.left-bar {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background: #1c1d22;
}

.error-message {
  font-size: 15px;
  font-weight: bold;
  line-height: 1.07;
  letter-spacing: normal;
  color: #ee3e37;
}

@media screen and (max-width: 40em) {
  .logo,
  .menu {
    display: none;
  }

  .content {
    margin: 0;
  }

  body,
  .container {
    height: auto;
    overflow: auto;
  }
}
