@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
.container {
  display: flex;
  width: 100%;
  overflow-y: auto;
  height: 100vh;
  background-color: #ccc;
}

.container-content {
  display: flex;
  width: 100%;
  height: 100%;
}

.bp-header {
  display: flex;
}

.logo {
  height: 60%;
  display: flex;
  align-self: center;
}

.left {
  float: right;
}

.heading {
  font-size: 0.75em;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.header {
  align-items: center;
  display: flex;
  position: fixed;
  height: 66px;
  width: 100%;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 50;
  justify-content: space-between;
  box-shadow:
    0 1px 3px 0 rgba(33, 33, 33, 0.2),
    0 1px 1px 0 rgba(33, 33, 33, 0.14),
 0 2px 1px -1px rgba(33, 33, 33, 0.12);
}

.header h1 {
  float: left;
  margin: 0;
  padding: 0 16px;
  font-size: 18px;
  line-height: 56px;
  font-weight: 400;
  color: #000;
}

.header nav {
  flex: 1 1;
  align-self: flex-end;
  font-size: 100%;
}

.header nav a {
  display: inline-block;
  height: 66px;
  line-height: 66px;
  padding: 0 16px;
  text-align: center;
  min-width: 50px;
  background: hsla(0, 0%, 100%, 0);
  will-change: background-color;
  text-decoration: none;
  color: #000;
}

.header nav a:hover {
  color: #faa722;
}

.sidebar {
  background: #212229;
  width: 88px;
  height: calc(100vh - 66px);
  display: flex;
}

.sidebar ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.sidebar-item {
  background: #212229;
  height: 88px;
  width: 88px;
}

.sidebar-item a {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid #212229;
  line-height: 1.5;
  cursor: pointer;
}

.sidebar-item a:hover,
.sidebar-item a:active,
.sidebar-item a:focus {
  border-left: 3px solid #3abc98;
}

#outlined-bare {
  font-family: Lato-Bold;
  letter-spacing: 0;
  text-align: center;
  border-radius: 0;
}

.login {
  margin: 0 auto;
  max-width: 450px;
  padding-top: 120px;
}

.login .inputContainer {
  margin: 20px 0;
}

.forgotPass a {
  float: right;
  color: #3abc98;
  font-size: 12px;
}

.login form label {
  font-weight: 700;
  font-size: 12px;
  color: #6b6e73;
  letter-spacing: 1.25px;
  line-height: 16px;
  text-transform: uppercase;
}

.login .heading {
  font-weight: 700;
  font-size: 32px;
  color: #191c1f;
  letter-spacing: 0;
  line-height: 40px;
}

.login input[type='text'],
.login input[type='password'] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #d6dadf;
  border-radius: 2px;
}

input:focus {
  background-color: #f3f5f8;
}

.poll-canvas {
  width: 100%;
}

.poll-canvas-header {
  width: 100%;
  height: 32px;
  background: #3b3c47;
  box-shadow: 0 1px 3px 0 #6b6e73;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.poll-canvas-container {
  height: calc(100vh - 200px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.poll-canvas-frame {
  width: 500px;
  height: 500px;
  background-color: white;
  display: flex;
  align-self: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 60px 12px;
  padding: 30px;
}

.poll-slider-form {
  width: 100%;
  margin: 0 auto;
}

.poll-slider-icon {
  margin-top: 115px;
}

.poll-sections {
  display: flex;
  justify-content: space-between;
}

.slider-config {
  display: flex;
  background: #3b3c47;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  padding: 10px 20px;
  color: white;
  min-height: 120px;
}

.slider-config label {
  margin: 10px 0;
  text-align: left;
  font-size: 12px;
  color: #fff;
  letter-spacing: 1.25px;
  line-height: 16px;
  text-transform: uppercase;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif;
  overflow: hidden;
  margin: 0;
  color: #191c1f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: #fff;
  outline: none;
}

a:hover {
  color: #ddd;
}

li {
  text-decoration: none;
}

/* Helper classes */

.cf::before,
.cf::after {
  content: ' ';
  display: table;
}

.cf::after {
  clear: both;
}

.container {
  overflow-y: auto;
  height: 100vh;
}

.content {
  position: relative;
  min-height: 300px;
}

.info {
  font-size: 1.1em;
  font-weight: bold;
  padding: 20vh 1em 0;
  text-align: center;
  color: #47484c;
}

.main-content {
  margin: 0;
  padding: 2em;
  text-align: center;
}

.product {
  display: inline-block;
  width: 200px;
  height: 200px;
  margin: 10px;
  border-radius: 5px;
  background: #1c1d22;
}

.left-bar {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background: #1c1d22;
}

.error-message {
  font-size: 15px;
  font-weight: bold;
  line-height: 1.07;
  letter-spacing: normal;
  color: #ee3e37;
}

@media screen and (max-width: 40em) {
  .logo,
  .menu {
    display: none;
  }

  .content {
    margin: 0;
  }

  body,
  .container {
    height: auto;
    overflow: auto;
  }
}

