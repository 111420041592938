.poll-canvas {
  width: 100%;
}

.poll-canvas-header {
  width: 100%;
  height: 32px;
  background: #3b3c47;
  box-shadow: 0 1px 3px 0 #6b6e73;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.poll-canvas-container {
  height: calc(100vh - 200px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.poll-canvas-frame {
  width: 500px;
  height: 500px;
  background-color: white;
  display: flex;
  align-self: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 60px 12px;
  padding: 30px;
}
