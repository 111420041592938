.login {
  margin: 0 auto;
  max-width: 450px;
  padding-top: 120px;
}

.login .inputContainer {
  margin: 20px 0;
}

.forgotPass a {
  float: right;
  color: #3abc98;
  font-size: 12px;
}

.login form label {
  font-weight: 700;
  font-size: 12px;
  color: #6b6e73;
  letter-spacing: 1.25px;
  line-height: 16px;
  text-transform: uppercase;
}

.login .heading {
  font-weight: 700;
  font-size: 32px;
  color: #191c1f;
  letter-spacing: 0;
  line-height: 40px;
}

.login input[type='text'],
.login input[type='password'] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #d6dadf;
  border-radius: 2px;
}

input:focus {
  background-color: #f3f5f8;
}
