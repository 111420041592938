.container {
  display: flex;
  width: 100%;
  overflow-y: auto;
  height: 100vh;
  background-color: #ccc;
}

.container-content {
  display: flex;
  width: 100%;
  height: 100%;
}
